@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  color: #fff;
  font-family: 'Playfair Display', serif !important;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: unset;
}

ul,
li {
  list-style: none;
}

.font-kaushan {
  font-family: 'Kaushan Script', cursive;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
  width: 10px !important;
  height: 10px !important;
}