.menu {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 25%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100px);
  transition: 0.5s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  li {
    font-size: 1.5rem;
    position: relative;
    transition: 0.3s ease-in-out;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 5px;
      width: 0;
      background-color: #fff;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      padding-left: 24px;
    }

    &:hover::before {
      width: 15px;
    }
  }

  li ~ li {
    margin-top: 1rem;
  }
}

@media (max-width: 767px) {
  .menu {
    width: 30%;
  }
  .menu li a {
    font-size: 18px;
  }
}
