.app-container {
  min-height: 100vh;
  width: 100%;
  background-color: #161616;
  overflow-x: hidden;
  transform-style: preserve-3d;
}

.main {
  position: relative;
  z-index: 5;
  transform-origin: left;
  transform-style: preserve-3d;
  transition: 0.5s ease-in-out;

  .content {
    min-height: 100vh;
    position: relative;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  background-color: #181719;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.app-container.active .main {
  overflow: hidden;
  animation: main-animation 0.5s ease-in-out;
  cursor: pointer;
  transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
}

@keyframes main-animation {
  from {
    transform: rotate(0);
  }
  to {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
  }
}

.main-container {
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform-style: preserve-3d;
    transform-origin: left;
    transition: 0.5s ease-in-out;
    background-color: #fff;
  }

  &::before {
    z-index: -1;
    opacity: 0.15;
  }

  &::after {
    z-index: -2;
    opacity: 0.1;
  }
}

.app-container.active .main-container::before {
  animation: main-before 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateZ(215px) scale(0.5);
}

@keyframes main-before {
  0% {
    transform: translate(0);
  }
  5% {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
  }
  100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(215px) scale(0.5);
  }
}

.app-container.active .main-container::after {
  animation: main-after 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateZ(120px) scale(0.5);
}

@keyframes main-after {
  0% {
    transform: translate(0);
  }
  20% {
    transform: perspective(1300px) rotateY(20deg) translateZ(310px) scale(0.5);
  }
  100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(120px) scale(0.5);
  }
}

@media (max-width: 767px) {
  .app-container.active .main-container::after {
    transform: perspective(1300px) rotateY(20deg) translateZ(80px) scale(0.5);
  }

  .app-container.active .main {
    transform: perspective(1300px) rotateY(20deg) translateZ(140px) scale(0.5);
  }

  @keyframes main-animation {
    from {
      transform: rotate(0);
    }
    to {
      transform: perspective(1300px) rotateY(20deg) translateZ(140px) scale(0.5);
    }
  }

  .app-container.active .main-container::before {
    transform: perspective(1300px) rotateY(20deg) translateZ(20px) scale(0.5);
  }

  @keyframes main-before {
    0% {
      transform: translate(0);
    }
    5% {
      transform: perspective(1300px) rotateY(20deg) translateZ(140px) scale(0.5);
    }
    100% {
      transform: perspective(1300px) rotateY(20deg) translateZ(20px) scale(0.5);
    }
  }

  .app-container.active .main-container::after {
    transform: perspective(1300px) rotateY(20deg) translateZ(80px) scale(0.5);
  }

  @keyframes main-after {
    0% {
      transform: translate(0);
    }
    20% {
      transform: perspective(1300px) rotateY(20deg) translateZ(140px) scale(0.5);
    }
    100% {
      transform: perspective(1300px) rotateY(20deg) translateZ(80px) scale(0.5);
    }
  }
}