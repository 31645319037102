.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 5rem;

  &__content {
    max-width: 1120px;
    height: 100%;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
}

.menu-toggle {
  --size: 30px;
  width: var(--size);
  height: var(--size);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  cursor: pointer;

  .line {
    width: 100%;
    height: 5px;
    background-color: #fff;
    border-radius: 16px;
    position: absolute;
    transform-origin: center;
  }

  .line.first {
    top: 0;
    margin-top: 0;
    transform: rotate(0);
    transition: transform 0.5s, margin 0.5s 0.5s;
  }

  .line.second {
    top: 50%;
    transform: scaleX(1) translateY(-50%);
    transition: 0.5s 0.5s;
  }

  .line.third {
    bottom: 0;
    margin-bottom: 0;
    transform: rotate(0);
    transition: transform 0.3s, margin 0.5s 0.5s;
  }

  .line.second.active {
    transform: scaleX(0) translateY(-5px);
    transition: 0.2s;
  }

  .line.first.active {
    margin-top: 13px;
    transform: rotate(45deg);
    transition: margin 0.3s, transform 0.5s 0.5s;
  }

  .line.third.active {
    margin-bottom: 13px;
    transform: rotate(135deg);
    transition: margin 0.3s, transform 0.5s 0.5s;
  }
}
